import { FilePdfOutlined, IdcardOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Upload
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
const CONN_KEY_SENAT = process.env.REACT_APP_NOT_SECRET_CODE_R;
const { Option } = Select;

const UpdateStaff = () => {
  const [data, setData] = useState({});
  const [brands, setBrands] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageSH, setSelectedImageSH] = useState(null);
  const { id } = useParams();
  const [permissions, setPermissions] = useState({});
  const navigate = useNavigate();
  useEffect(() => {

    // Fetch permissions from session storage
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
    console.log('Stored Permissions:', storedPermissions);

    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
    }

    // Check if GuidesAzAdd permission is 0 and navigate if true
    if (storedPermissions && storedPermissions[0].StafAzEdit === 0) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    // Fetch brand data from the server
    const fetchBrands = async () => {
      try {
        const response = await axios.get(CONN_KEY_SENAT + "Staff.php");
        setBrands(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    // Fetch user data based on Id
    const fetchData = async () => {
      try {
        const response = await axios.get(`${CONN_KEY_SENAT}Staff.php?id=${id}`);
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const onFinish = async (values) => {
    try {
      const response = await axios.put(`${CONN_KEY_SENAT}updateStaff.php`, values, {
        params: {
          id: id,
        },
      });

      if (response.data.status === 0) {
        message.error(response.data.message);
        return;
      }

      //  Check if Status value is null or an empty string, and remove outwhy and outdate from the form data
      // if (!values.Status || values.Status === "") {
      //   delete values.outwhy;
      //   delete values.outdate;
      // }

      message.success(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      form.setFieldsValue(data);
    }
  }, [form, data]);

  const handleSubmit = () => {
    if (selectedImageSH) {
      const formData = new FormData();
      formData.append("file", selectedImageSH);

      // Make a POST request to updateimageStaff.php
      fetch(`${CONN_KEY_SENAT}updateimageStaff.php?id=${id}`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data
          if (data.success) {
            message.success(data.message); // Success message
          } else {
            message.error(data.message); // Error message
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          message.error(error);
        });
    }
  };

  const handleSubmitPP = () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", selectedImage);

      // Make a POST request to updateimageStaffPP.php
      fetch(`${CONN_KEY_SENAT}updateimageStaffPP.php?id=${id}`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data
          if (data.success) {
            message.success(data.message); // Success message
          } else {
            message.error(data.message); // Error message
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          message.error(error);
        });
    }
  };

  return (
    <div className="container">
      <h1>Vəsiqə nömrəsi {data.userid}</h1>
      <Form
        form={form}
        onFinish={onFinish}
        encType="multipart/form-data"
        className="form-Add container"
        layout="vertical"
      >
        <Card>
          <div style={{ gap: "10px" }}>
            <Row gutter={[16, 16]} className="formfsd">
              <Form.Item className="ant-form-item" label="Vn №" name="userid">
                <Input style={{ width: "100%" }} size="large" disabled />
              </Form.Item><Form.Item className="ant-form-item" label="Kart" name="cardid">
                <Input type='password' style={{ width: "100%" }} size="large"  />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="İstifadəçi adı"
                name="username"
              >
                <Input style={{ width: "100%" }} size="large" />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Soyad"
                name="surename"
              >
                <Input style={{ width: "100%" }} size="large" />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Ata adı"
                name="dadname"
              >
                <Input
                  style={{ width: "100%" }}
                  size="large"
                  placeholder={data.dadname}
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="İstifadəçi Fin"
                name="Fin"
              >
                <Input
                  style={{ width: "100%" }}
                  size="large"
                  placeholder={data.Fin}
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Doğum günü"
                name="datebhrd"
              >
                <Input
                  style={{ width: "100%" }}
                  size="large"
                  placeholder={data.datebhrd}
                />
              </Form.Item>
              <Form.Item className="ant-form-item" label="Ölkə" name="Country">
                <Input
                  style={{ width: "100%" }}
                  size="large"
                  placeholder={data.Country}
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Telefon nömrəsi"
                name="Phone"
              >
                <Input
                  style={{ width: "100%" }}
                  size="large"
                  placeholder={data.Phone}
                />
              </Form.Item>
              <Form.Item className="ant-form-item" label="Təhsili" name="edu">
                <Input
                  style={{ width: "100%" }}
                  size="large"
                  placeholder={data.edu}
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="İşə qəbul tarixi"
                name="recruitment"
              >
                <Input
                  style={{ width: "100%" }}
                  name="recruitment"
                  size="large"
                  placeholder="Baku"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Qeydiyat ünvanı"
                name="lokatiogeyd"
              >
                <Input
                  style={{ width: "100%" }}
                  name="lokatiogeyd"
                  size="large"
                  placeholder="Baku"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Yaşadığı ünvanı"
                name="lokationLive"
              >
                <Input
                  style={{ width: "100%" }}
                  name="lokationLive"
                  size="large"
                  placeholder="Baku"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Akkreditasiya"
                name="Catagory"
              >
                <Input
                  style={{ width: "100%" }}
                  name="Catagory"
                  size="large"
                  placeholder="S3"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Iş yeri"
                name="lokationW"
              >
                <Select
                  placeholder="İş yeri"
                  allowClear
                  size="large"
                  name="lokationW"
                >
                  <Option value="Extra">Extra</Option>
                  <Option value="Ellips">Ellips</Option>
                  <Option value="Sahara">Sahara</Option>
                  <Option value="Georgia">Georgia</Option>
                </Select>
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Departament"
                name="Departament"
              >
                <Select
                  placeholder="Service"
                  allowClear
                  size="large"
                  name="Departament"
                >
                  <Option value="Service">Service</Option>
                  <Option value="Marketing">Marketing</Option>
                  <Option value="Security">Security</Option>
                  <Option value="Mətbəx">Mətbəx</Option>
                  <Option value="Texnik">Texnik</Option>
                  <Option value="Xadimə">Xadimə</Option>
                  <Option value="Kassir">Kassir</Option>
                  <Option value="Mühasib">Mühasib</Option>
                  <Option value="AUDIT">AUDIT</Option>
                </Select>
              </Form.Item>
              <Form.Item className="ant-form-item" label="Status" name="Status">
                <Select allowClear size="large" name="Status">
                  <Option value="1">Islemir</Option>
                  <Option value="2">Isleyir</Option>
                  <Option value="Geo">Georgia</Option>
                </Select>
              </Form.Item>
              <Form.Item className="ant-form-item" label="Vəzifə" name="vezife">
                <Input
                  style={{ width: "100%" }}
                  name="vezife"
                  size="large"
                  placeholder="Barmen"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="3x4"
                name="filePP"
                disabled
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  const fileList = e && e.fileList;
                  if (fileList) {
                    return fileList.slice(-1); // Limit the fileList to the last selected file
                  }
                  return [];
                }}
              >
                <Upload
                  name="filePP"
                  method="PUT"
                  maxCount={1}
                  listType="picture"
                  beforeUpload={(file) => {
                    setSelectedImage(file); // Store the selected image file
                    return false; // Prevent upload from happening immediately
                  }}
                >
                  {selectedImage ? (
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      style={{ width: 50 }}
                      alt="Şəxsiyyət vəsiqəsi"
                    />
                  ) : data.filePP ? (
                    <img
                      src={`${CONN_KEY_SENAT}Staff/3x4Staff/${data.filePP}`}
                      style={{ width: 50 }}
                      alt="Şəxsiyyət vəsiqəsi"
                    />
                  ) : null}
                </Upload>
                <Button type="primary" onClick={handleSubmitPP}>
                  Yenilə
                </Button>
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Scan Şəxsiyyət vəsiqəsi"
                name="fileSH"
                disabled
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  const fileList = e && e.fileList;
                  if (fileList) {
                    return fileList.slice(-1); // Limit the fileList to the last selected file
                  }
                  return [];
                }}
              >
                <Upload
                  name="fileSH"
                  method="PUT"
                  maxCount={1}
                  listType="picture"
                  beforeUpload={(file) => {
                    setSelectedImageSH(file); // Store the selected image file
                    return false; // Prevent upload from happening immediately
                  }}
                >
                  {selectedImageSH ? (
                    <img
                      src={URL.createObjectURL(selectedImageSH)}
                      style={{ width: 150 }}
                      alt="Şəxsiyyət vəsiqəsi"
                    />
                  ) : data.fileSH ? (
                    <img
                      src={`${CONN_KEY_SENAT}Staff/scanStaff/${data.fileSH}`}
                      style={{ width: 150 }}
                      alt="Şəxsiyyət vəsiqəsi"
                    />
                  ) : null}
                </Upload>
                <Button type="primary" onClick={handleSubmit}>
                  Yenilə
                </Button>
              </Form.Item>
            </Row>
            <Space>
              <Link to={`/CardPersonal/${data.id}`} target="_blank">
                <Button icon={<IdcardOutlined />}>Cart</Button>
              </Link>
              <Link to={`/PDFStaff/${data.id}`} target="_blank">
                <Button icon={<FilePdfOutlined />}>PDF</Button>
              </Link>
              <Button
                htmlType="submit"
                //
                type="primary"
                className="ColorMain"
              >
                Yenilə
              </Button>
            </Space>
          </div>
        </Card>
      </Form>
    </div>
  );
};

export default UpdateStaff;
