import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Space, Table, Tag } from "antd";
import axios from 'axios';
import MapC from "./Map";
import MapM2 from "./MapM2";
const CONN_KEY_R = process.env.REACT_APP_NOT_SECRET_CODE_RR;
const { Content } = Layout;

const desiredFlourIds = ['L1', 'L2', 'R1', 'R2', 'MM1', 'ML2', 'MR2', 'B1', 'B2'];

const Printpage = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userLevel, setUserLevel] = useState('');

    const navigate = useNavigate();

    const fetchData = useCallback(async () => {
        try {
            const response = await axios.get(`https://appmobile.svurguns.cyou/Data/api/app123/printrezervs.php`);

            if (Array.isArray(response.data.reservations)) {
                const currentDate = new Date().toLocaleDateString('en-GB');
                const filteredReservations = response.data.reservations.filter(item => {
                    const reservationDate = new Date(item.created_at).toLocaleDateString('en-GB');
                    return desiredFlourIds.includes(item.selectedFlourId) && reservationDate === currentDate;
                });

                filteredReservations.sort((a, b) => {
                    const indexA = desiredFlourIds.indexOf(a.selectedFlourId);
                    const indexB = desiredFlourIds.indexOf(b.selectedFlourId);
                    if (indexA !== indexB) return indexA - indexB;
                    return parseInt(a.Masa, 10) - parseInt(b.Masa, 10);
                });

                setData(filteredReservations);
            } else {
                console.error("Invalid data type or structure:", response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const storedUserLevel = localStorage.getItem('s');
        if (storedUserLevel) setUserLevel(storedUserLevel);
    }, []);

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('username') !== null;
        if (!isLoggedIn) navigate('/');
    }, [navigate]);

    useEffect(() => {
        fetchData();
        const printTimeout = setTimeout(handlePrint, 2000);
        return () => clearTimeout(printTimeout);
    }, [fetchData]);

    const handlePrint = () => {
        const invoiceSection = document.getElementById('invoice-section');
        if (invoiceSection) {
            const printDateDiv = document.createElement('div');
            printDateDiv.innerHTML = `<p style="text-align: center; bottom:10px;">Printed on: ${new Date().toLocaleString()} By Marketing | VERSİON: 1.0.1</p>`;
            invoiceSection.appendChild(printDateDiv);

            const originalContents = invoiceSection.innerHTML;
            document.body.innerHTML = originalContents;
            /* The commented out line `// window.location.reload();` is a JavaScript code that would reload the current page when executed. This line is typically used to refresh the page, which can be useful in scenarios where you want to reset the page state or content to its initial state. */
            /* The line `// window.print();` is a JavaScript code that triggers the browser's print functionality. When this line is executed, it opens the print dialog box allowing the user to print the content of the current page. This is a common way to provide a print option for users to print the specific content displayed on the webpage. */
            window.print();

            invoiceSection.removeChild(printDateDiv);
            window.location.reload();  // Reload the page to restore the original document structure
        } else {
            console.error("Invoice section not found in DOM.");
        }
    };

    const isNotTodayOrAfterEndDay = (record) => {
        const today = new Date();
        const date = new Date(record.created_at);
        return today.toDateString() !== date.toDateString() || (record.endDay && today > new Date(record.endDay));
    };

    const rowClassName = (record) => isNotTodayOrAfterEndDay(record) ? "grayscale disabled" : "";

    const selectedFlourIdMap = {
        L1: "L1 Position",
        L2: "L2 Position",
        R1: "R1 Position",
        R2: "R2 Position",
        B1: "B1 Position",
        B2: "B2 Position",
        MM1: "MM1 Position",
        MR2: "MR2 Position",
        ML2: "ML2 Position",
    };

    const columns = [
        {
            title: 'Ad',
            dataIndex: 'username',
            key: 'username',
            render: (text, record) => <div><p>{text} {record.surename}</p></div>,
        },
        { title: 'Masa', dataIndex: 'Masa', key: 'Masa' },
        { title: 'Not', dataIndex: 'note', key: 'note', width: 200 },
        {
            title: 'Mərtəbə Ərazi',
            dataIndex: 'selectedFlourId',
            key: 'selectedFlourId',
            render: (text) => selectedFlourIdMap[text] || "Unknown",
            width: 100,
        },
        {
            title: 'R Tarix / Saat',
            dataIndex: 'selectedDay',
            key: 'selectedDay',
            render: (text, record) => {
                const date = new Date(text);
                return <div><p>{date.toLocaleDateString('en-GB')} {record.selectedTime}</p></div>;
            },
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            width: 100,
            key: 'created_at',
            render: (text) => {
                const date = new Date(text);
                return date.toLocaleString('en-GB', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                });
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "pending" ? "yellow" : text === "complete" ? "green" : "red"}>
                    {text}
                </Tag>
            ),
        },
    ];

    const groupDataByFlourId = (data) => {
        return data.reduce((acc, reservation) => {
            if (!acc[reservation.selectedFlourId]) {
                acc[reservation.selectedFlourId] = [];
            }
            acc[reservation.selectedFlourId].push(reservation);
            return acc;
        }, {});
    };

    const groupedData = groupDataByFlourId(data);

    const renderFlourIdGroup = (flourIds, groupName) => {
        const groupData = flourIds.reduce((acc, flourId) => {
            if (groupedData[flourId] && groupedData[flourId].length > 0) {
                acc = acc.concat(groupedData[flourId]);
            }
            return acc;
        }, []);

        if (groupData.length > 0) {
            return (
                <div className="group-container">
                    <div className="group-items">
                        <Table
                            columns={columns}
                            dataSource={groupData}
                            loading={isLoading}
                            pagination={false}
                            rowClassName={rowClassName}
                        />
                    </div>
                </div>
            );
        }
        return null;
    };

    const renderPositionGroups = () => {
        console.log("Grouped Data for Rendering:", groupedData);
    
        return (
            <>
                {Object.keys(groupedData).map((flourId) => {
                    const flourData = groupedData[flourId];
                    if (flourData && flourData.length > 0) {
                        return (
                            <div key={flourId} className="group-container">
                                <h2 className="printpagetop">{flourId} Reservations</h2>
                                <Table
                                    columns={columns}
                                    dataSource={flourData.map((item, index) => ({ ...item, key: index }))}
                                    pagination={false}
                                    bordered
                                />
                                <div className="page-break"></div>
                            </div>
                        );
                    }
                    return null; // Skip rendering for empty groups
                })}
            </>
        );
    };
    
    return (
        <div className="dibhaa pritlistrezv" id="invoice-section">
            {renderPositionGroups()}
            <Space>
                <MapC />
                <MapM2 />
            </Space>
        </div>
    );
}

export default Printpage;
