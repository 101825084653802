import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, List, Tooltip, Button, Drawer } from "antd";

const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;

const QueueList = () => {
  const [queueData, setQueueData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  // Fetch queue data from the API
  const fetchQueueData = async () => {
    try {
      const response = await axios.get(`${CONN_KEY}app123/get_allque.php`, {
        headers: {
          Authorization: `Bearer 784f462b4ee4e847ccfe44db65f51a9c`,
          "Content-Type": "application/json"
        },
      });
      
      console.log("API response:", response.data);

      if (response.data.queues && Array.isArray(response.data.queues)) {
        setQueueData(response.data.queues);
      } else {
        setQueueData([]);
      }

      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQueueData();
  }, []);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data: {error}</div>;
  }

  return (
    <div className="dashboard">
      <Button type="primary" onClick={showDrawer}>Show Queue Info</Button>
      <Drawer
        title="Queue Information"
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible}
      >
        <Card bordered={false} className="cardQueue">
          <List>
            {queueData.length > 0 ? (
              queueData.map((queue, index) => (
                <List.Item key={index}>
                  <Tooltip title={`Sıra nömrəsi: ${queue.queue_number}`}>
                    <strong>Sıra/
                      N: {queue.queue_number}:</strong> {queue.username ? `${queue.username} ${queue.surename} (${queue.phone})` : "Unknown User"}
                  </Tooltip>
                </List.Item>
              ))
            ) : (
              <List.Item>No queue numbers available.</List.Item>
            )}
          </List>
        </Card>
      </Drawer>
    </div>
  );
};

export default QueueList;
